.btn-primary {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.btn-outline-primary {
  background-color: var(--body-background) !important;
  border-color: var(--primary) !important;
  color: var(--primary) !important;
}

.btn-primary:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.025), rgba(0, 0, 0, 0.025));
}

.btn-primary:active {
  background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
}

.btn.disabled.pointer-events-on-disable {
  pointer-events: auto;
}
