.overlay-btn {
  position: absolute;
  background-color: transparent;
  border: 2px dashed rgba(255, 255, 255, 0.15);
  cursor: pointer;
}

.overlay-btn:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
