:root {
  --body-background: #fffcf8;
  --brand-background: #5c13bd;
  --dark-brand-background: #5011a3;
  --primary: #1fd187;
  --light-primary: #57dda5;
  --secondary: #d2ffec;
  --tertiary: #feadc9;
  --black: #000;
  --graphite: #545454;
}

html {
  text-size-adjust: 100%;
}

@font-face {
  font-family: Lato;
  src: url(./fonts/Lato/Lato-Regular.ttf);
}

@font-face {
  font-family: Castoro;
  src: url(./fonts/Castoro/Castoro-Regular.ttf);
}

@font-face {
  font-family: Raleway;
  src: url(./fonts/Raleway/static/Raleway-Regular.ttf);
}

body {
  font-family: Lato;
  margin: 0;
  padding: 0;
  background-color: var(--body-background);
  user-select: none;
}

@media (max-width: 768px) {
  body {
    font-size: 0.8rem;
  }
}

.pointer {
  cursor: pointer;
}

.opacity-1 {
  opacity: 1 !important;
}

.vh-100 {
  height: 100vh;
}

.vh-50 {
  height: 50vh;
}

.vh-25 {
  height: 25vh;
}

.w-33 {
  width: 33% !important;
}

.h-33 {
  height: 33% !important;
}

.font-weight-bold {
  font-weight: bold;
}

.btn {
  margin: 0.1rem;
}

.p-05 {
  padding: 0.125rem !important;
}

.ar-1-1 {
  aspect-ratio: 1/1;
}

.ar-4-3 {
  aspect-ratio: 4/3;
}

.ar-16-9 {
  aspect-ratio: 16/9;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-100 {
  opacity: 1;
}

.z-index-lowest {
  z-index: 10;
}

.z-index-low {
  z-index: 50;
}

.z-index-normal {
  z-index: 100;
}

.z-index-high {
  z-index: 150;
}

.z-index-highest {
  z-index: 9999;
}

.rounded-full {
  border-radius: 9999px;
}

.font-lg {
  font-size: 4rem;
}

.w-16px {
  width: 16px !important;
}

.w-24px {
  width: 24px !important;
}

.w-100px {
  width: 100px !important;
}

.w-200px {
  width: 200px !important;
}

.w-300px {
  width: 300px !important;
}

.h-16px {
  height: 16px !important;
}

.h-24px {
  height: 24px !important;
}

.h-300px {
  height: 300px !important;
}

.max-height-300px {
  max-height: 300px !important;
}

.max-height-50vh {
  max-height: 50vh !important;
}

.object-fit-contain {
  object-fit: contain;
  max-width: 100%;
}

.pre-wrap {
  font-family: inherit;
  white-space: pre-wrap;
}

.logo {
  padding: 90px;
}

.sign-in-font {
  font-family: Raleway;
}

.center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

.socials-container {
  background: var(--light-primary);
}

.sing-up-text {
  color: var(--black);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sign-in-button {
  background-color: var(--brand-background);
  width: 150px;
  border-radius: 10px;
  padding: 8px;
}

.sign-in-button:hover {
  background-color: var(--dark-brand-background);
}

.welcome-text {
  color: var(--graphite);
}

.text-small {
  font-size: 0.75rem;
}
