.meeting {
  height: calc(100vh - 4rem);
  width: 100vw;
  text-align: center;
}

.meeting-main-activity {
  height: 100%;
  margin: auto;
}

.meeting-controls {
  background-color: rgba(0, 0, 0, 0.5);
  height: 4rem;
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.meeting-controls .devices-control {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.25rem;
  margin-right: 0.25rem;
  padding-top: 0.35rem;
  padding-bottom: 0;
  border-radius: 0.25rem;
  color: #ffffff;
  font-size: 1.2rem;
  width: 2.2rem;
  text-align: center;
}

.meeting-controls .devices-control:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
