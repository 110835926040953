.navigation-pane {
  width: 4rem;
  height: 100vh;
  background-color: var(--brand-background);
}

@media (max-width: 768px) {
  .navigation-pane {
    padding-bottom: 4rem;
  }
}

.navigation-pane-button {
  width: 3rem;
  height: 2.9rem;
  border-radius: 0.5rem;
  padding: 0;
  font-size: 1.8rem;
  background-color: var(--brand-background);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .navigation-pane {
    width: 3.2rem;
  }

  .navigation-pane-button {
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 0.5rem;
    font-size: 1.25rem;
  }
}

.navigation-pane-button:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
}

.navigation-pane-button:active {
  background-color: var(--primary);
}

.navigation-pane-button-selected {
  background-color: var(--primary);
}

.navigation-pane-button-selected:hover {
  background-color: var(--primary);
}
