.participant {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  position: relative;
}

.participant .identity {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.25rem;
  border-radius: 0.25rem;
  color: #ffffff;
  font-size: 0.6rem;
  max-width: calc(100% - 1rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.participant .user-photo img {
  object-fit: contain;
}

.participant .meeple-preview {
  position: absolute;
  right: 3px;
  top: 4px;
  width: 25%;
  height: 25%;
}

@media (max-width: 576px) {
  .participant .identity {
    display: none;
  }
}
