.sign-in-as {
  display: flex;
  justify-items: center;
  border-radius: 1rem;
  text-align: center;
  width: 100%;
  height: 100%;
  box-shadow: rgb(0 0 0 / 15%) 0px 5px 0px 0px;
}

.sign-in-as:active {
  box-shadow: none;
}

.sign-in-as-teacher {
  background-color: var(--brand-background);
}

.sign-in-as-attendee {
  background-color: var(--primary);
}
