.nav-tabs .nav-link.active::after {
  background-color: #fff;
  position: absolute;
  width: 101%;
  height: 4px;
  content: "";
  margin-left: -16px;
  top: 95%;
  z-index: 1;
}

.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-link.active {
  isolation: unset;
}
